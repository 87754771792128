// import Vue from 'vue'
import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_SERVER
// axios.defaults.baseURL = 'http://192.168.1.6:8100/llcb'
// axios.defaults.baseURL = 'https://nllcbtest.lanlingcb.com/llcb'
// axios.defaults.baseURL = 'https://nllcbdev.lanlingcb.com/llcb'
// axios.defaults.baseURL = 'https://nllcb.kaishuihu.com/llcb'
// axios.defaults.baseURL = 'https://prenllcb.kaishuihu.com/llcb'


axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// axios.defaults.headers.post['Content-Type'] = 'application/json'
import qs from 'qs'
let METHOD = {
  GET: 'get',
  POST: 'post'
}
async function request({ url, method, data, isformData }) {
  if (isformData) {
    data = data ? data : {}
    let formData = qs.stringify(data)
    switch (method) {
      case METHOD.GET:
        return axios.get(url, { params: data })
      case METHOD.POST:
        return axios.post(url, formData)
      default:
        return axios.get(url, { params: data })
    }
  } else {
    switch (method) {
      case METHOD.GET:
        return axios.get(url, { params: data })
      case METHOD.POST:
        return axios.post(url, data)
      default:
        return axios.get(url, { params: data })
    }
  }

}
// 请求拦截
axios.interceptors.request.use((req) => {
  const token = sessionStorage.getItem('token')
  console.log(token,"token")
  // const token = Vue.ls.get('token')
  if(token){
    req.headers['authorization'] = token
  }
  return req
}, (error) => {
  // 对请求错误做点什么
  return Promise.reject(error);
})
// 接收拦截器
let goFlag = true
axios.interceptors.response.use((response) => {
  if(response.data.code == 401){
    if(goFlag){
      goFlag = false
      let hrefU = window.location.href
      let url = null
      if(hrefU.indexOf('bigscreenqdtest') > -1){
        url = 'https://nllcbtest.lanlingcb.com/'
      }else if(hrefU.indexOf('prebigscreenqd') > -1){
        url = 'https://prenllcb.kaishuihu.com/'
      }else{
        url = 'https://nllcb.kaishuihu.com/'
      }
      setTimeout(() => {
        goFlag = true
      }, 5000);
      console.log(url)
    }
    return Promise.reject()
  }else{
    return response.data;
  }
}, (error) => {
  // 网络状态码异常
  switch (error.response.status) {
    case 404:
      // alert("您访问的页面找不到")
      break
    case 500:
      // alert("服务器开小差了，稍后再试吧")
      break;
  }
  // 对响应错误做点什么
  return Promise.reject(error);
})
export {
  METHOD,
  request
}
